import React from 'react';
import { fetchSearch, clearSearch } from '../../api/search/actions'
import { connect } from 'react-redux'
import { store } from '../../store'
import { connectWithStore, Spinner } from '../../utils'
//import SearchResults from './searchResults'
import { SvgSearchRed, SvgCloseSearch } from '../../components/svg'

import $ from 'jquery'
import _ from 'lodash'
import { ANALYTICS_ID } from '../../constants';


class SearchInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            search_text: "" + this.props.q || ""
        }
        this.onChange = this.onChange.bind(this)
        this.callSearch = this.callSearch.bind(this)
        this.callSearchThrottle = _.debounce(value => this.callSearch(value), 300);
    }

    // componentDidMount () {
    //     $(this.refs[this.props.searchResultsAttributeName]).focus()
    //     this.doSearch()
    // }

    clearSearch() {
        clearTimeout(this.timer)
        this.setState({ search_text: "" })
        this.props.dispatch(clearSearch())
        
    }

    // doSearch() {
    //     if (this.props.searchOnLoad && this.state.search_text.length > 2) {
    //         const { dispatch } = this.props
    //         dispatch(this.props.fetchMethod(this.state.search_text))
    //     }
    //     if (this.props.searchOnLoad && this.state.search_text.length <= 2 && this.props.allowEmptyString) {
    //         const { dispatch } = this.props
    //         dispatch(this.props.fetchMethod(this.state.search_text))
    //         this.clearSearch()
    //     }
    // }

    callSearch(value) {
        const { dispatch } = this.props
        Promise.resolve(dispatch(this.props.fetchMethod(value, this.props.modalSearch)))
        .then(() => {
            if (gtag) {
                gtag('config', ANALYTICS_ID, {
                    'page_title' : gettext('Search results'), 
                    'page_path': `${window.location.pathname}?q=${value}`
                });
            }
        })
    }

    callSearchThrottle(value) {
        this.callSearch(value)
    }

    onChange(e) {
        const { dispatch } = this.props
        var search_text = e.target.value;
        this.setState({ search_text: search_text })
        if (!search_text) {
            // return dispatch(this.props.clearMethod())
            this.clearSearch()
        }

        if (search_text.length >= 2) {
            this.callSearchThrottle(search_text)
        } else {
            if (this.props.allowEmptyString) {
                this.callSearchThrottle("")
            }
        }
    }

    doSubmit(e) {
        if (e.key == 'Enter' && !this.props.skipRedirect && window.location.pathname !== "/leitarniðurstöður/") {
            window.location.href = '/leitarniðurstöður/?q=' + e.target.value
        }
    }


    render() {
        return (
            <div className={`search-input`}>
                <div className="input-container">
                    <SvgSearchRed />
                    <input ref={this.props.searchResultsAttributeName} type="text" placeholder={this.props.placeholder} onChange={this.onChange} onKeyPress={(e) => e.stopPropagation()} value={this.state.search_text} />
                </div>
                <span className="clear-search" onClick={this.clearSearch.bind(this)}>
                    {!this.props.hideClear && this.state.search_text && this.state.search_text.length !== 0 &&
                        <SvgCloseSearch />
                    }
                </span>
                {this.props.isFetching['search_songs'] && this.props.showSpinner &&
                    <Spinner />
                }
            </div>
        )
    }
}

SearchInput.defaultProps = {
    fetchMethod: fetchSearch,
    placeholder: gettext("Search for a song or an artist"),
    searchResultsAttributeName: React.createRef(),
    showResults: false,
    searchOnLoad: false,
    showSpinner: false,
    allowEmptyString: false,
    q: "",
}

function mapStateToProps(state) {
    var f = {
        search_results: {},
        isFetching: {},
    }

    f.search_results['search_songs'] = (state.search_songs === undefined) ? {} : state.search_songs.search_songs;
    f.isFetching['search_songs'] = (state.search_songs === undefined) ? false : state.search_songs.isFetching;

    return f;
}

export default connectWithStore(store, SearchInput, mapStateToProps);