import React from "react";
import { submitWishlistItem, fetchWishlist, upvoteWishlistItem } from '../../api/wishlist/actions'
import { connect } from 'react-redux'
import ReactPaginate from "react-paginate";
import { SvgCheckMark } from "../svg";

class Wishlist extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            cover: '',
            errors: {
                title: '',
                cover: '',
            },
            activePage: 1,
        }
        this.onChange = this.onChange.bind(this)
        this.voteForSong = this.voteForSong.bind(this)

        this.title = React.createRef()
        this.cover = React.createRef()
    }

    componentDidMount() {
        this.props.dispatch(fetchWishlist());
    }

    handlePageChange(pageObj) {
        this.setState({ activePage: pageObj.selected+1 });
    }

    sendInSongWish() {
        let songObj = { 'title': this.state.title, 'cover': this.state.cover, 'upvote': true }
        if (this.validateForm()) {
            Promise.resolve(this.props.dispatch(submitWishlistItem(songObj)))
                .then(() => {
                    this.setState({ ...this.state, title: '', cover: '' })
                })
                .then(() => {
                    this.props.dispatch(fetchWishlist())
                })
        }
    }

    onChange(e) {
        const { name, value } = e.currentTarget;
        this.setState({
            [name]: value
        })
    }

    validateForm() {
        let errors = this.state.errors
        let valid = true;
        Object.keys(errors).forEach(key => {
            switch (key) {
                case 'title':
                    var titleVal = this.title.current.value;
                    if (titleVal === "") {
                        errors.title = gettext('The title is required so we can register it properly')
                        valid = false;
                    } else {
                        errors.title = ''
                    }
                    break;
                case 'cover':
                    var coverVal = this.cover.current.value;
                    if (coverVal === "") {
                        errors.cover = gettext("Don't forget the cover itself")
                        valid = false;
                    } else {
                        errors.cover = ''
                    }
                    break;
                default:
                    break;
            }
        });
        this.setState({ errors: errors })
        return valid;
    }

    voteForSong(item) {
        Promise.resolve(this.props.dispatch(upvoteWishlistItem(item.id)))
            .then(() => {
                this.props.dispatch(fetchWishlist())
            })

    }

    hasUserVoted(item) {
        if (!item.has_voted) {
            return <button className="submit-vote" onClick={() => this.voteForSong(item)}>{gettext('Vote')}</button>
        }
    }

    fetchWishlistItems(items) {
        return (<><div className="row title-cover justify-content-center"><div className="col-6">{gettext('Title')}</div><div className="col-6">{gettext('Cover artist')}<span>{gettext('Votes')}</span></div><div className="col-4"></div></div>
            {items && items.map((item) => {
                if(item.song) {
                    return (<div className="row title-cover justify-content-center"><div className="col-6"><a href={item.song}>{item.title}</a></div><div className="col-6">{item.cover}<span>{item.vote_count}</span></div><div className="col-4"><SvgCheckMark /></div></div>)
                }
                return (<div className="row title-cover justify-content-center"><div className="col-6">{item.title}</div><div className="col-6">{item.cover}<span>{item.vote_count}</span></div><div className="col-4">{this.hasUserVoted(item)}</div></div>)
            }
            )}
        </>
        )
    }

    render() {
        const { wishlist } = this.props
        const itemsPerPage = 20
        const indexOfLastItem = this.state.activePage * itemsPerPage
        const indexOfFirstItem = indexOfLastItem -itemsPerPage
        const currentWishlistItems = wishlist.wishlist ? wishlist.wishlist.slice(indexOfFirstItem, indexOfLastItem) : []

        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8 col-md-12 col-15">
                        <h1 className="stylish">{gettext('The wishlist')}</h1>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8 col-md-12 col-15">
                        <p>{gettext('Here you can submit a song suggestion.')}</p>
                        <form className="wishlist-form">
                            <div>
                                <label htmlFor="song-title">{gettext('Song title')}</label>
                                <input id="song-title" type="text" name="title" onChange={this.onChange} ref={this.title} value={this.state.title}></input>
                                {this.state.errors.title.length > 0 ?
                                    <span className="error"> ({this.state.errors.title}) </span> : ''
                                }
                            </div>
                            <div>
                                <label htmlFor="performer-name">{gettext('Performer name')}</label>
                                <input id="performer-name" type="text" name="cover" onChange={this.onChange} ref={this.cover} value={this.state.cover}></input>
                                {this.state.errors.cover.length > 0 ?
                                    <span className="error"> ({this.state.errors.cover}) </span> : ''
                                }
                            </div>
                            <div className="row justify-content-center">
                                <div onClick={this.sendInSongWish.bind(this)} className="btn submit-song" type="button">{gettext('Submit suggestion')}</div>
                            </div>
                        </form>
                        {this.fetchWishlistItems(currentWishlistItems)}
                    </div>
                </div>
                {wishlist.wishlist && <div className="pagination-react">
                    <ReactPaginate
                    className="pagination"
                    disabledClassName="disabled"
                    activeClassName="active"
                    pageCount={Math.ceil((wishlist.wishlist).length/itemsPerPage)}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageChange.bind(this)}
                    previousLabel="⟨"
                    nextLabel="⟩"
                />
                </div>}
            </>

        )
    }
}

Wishlist.defaultProps = {
}

function mapStateToProps(state) {

    var f = {
        wishlist: {},
    }
    f.wishlist = (state.wishlist === undefined) ? [] : state.wishlist
    return f;
}

export default connect(mapStateToProps)(Wishlist);
