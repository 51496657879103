import React from "react";
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchInstruments } from "../../api/instrument/actions";
import { fetchSongbookDetail, fetchSongbooks, setActiveSongbook } from '../../api/songbooks/actions';
import SongbookListItem from '../../components/songbook';
import SongbookCreate from "../../components/songbook-create";
import { SvgPlusLargeRed, SvgSearchRed } from '../../components/svg';
import ReactPaginate from 'react-paginate';
import { Spinner } from '../../utils';

class SongbookContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      publicSongbooks: this.props.songbooks.publicSongbooks,
      privateSongbooks: this.props.songbooks.privateSongbooks,
      searchText: this.props.songbooks.searchText ? this.props.songbooks.searchText : '',
      searchTextPriv: this.props.songbooks.searchTextPriv ? this.props.songbooks.searchTextPriv : '',
      activeKey: this.props.songbooks.activeSongbook ? this.props.songbooks.activeSongbook : 'private',
      activePage: 1,
    }
    this.onSearchInputChange = this.onSearchInputChange.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(fetchInstruments())
    if (this.props.songbooks.publicSongbooks.results.length === 0) {
      this.props.dispatch(fetchSongbooks(null, true))
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.email !== prevProps.user.email) {
      if (this.props.user.isAuthenticated) {
        Promise.resolve(this.props.dispatch(fetchSongbooks(null, false)))
          .then(this.props.dispatch(fetchSongbooks(null, true)))
      } else {
        this.props.dispatch(fetchSongbooks(null, true))
      }
    }
    if (JSON.stringify(this.props.songbooks.publicSongbooks) != JSON.stringify(prevProps.songbooks.publicSongbooks)) {
      this.setState({
        publicSongbooks: this.props.songbooks.publicSongbooks
      })
      if (this.props.songbooks.publicSongbooks.previous == null) {
        this.setState({
          activePage: 1
        })
      }
    }
    if (JSON.stringify(this.props.songbooks.privateSongbooks) != JSON.stringify(prevProps.songbooks.privateSongbooks)) {
      this.setState({
        privateSongbooks: this.props.songbooks.privateSongbooks
      })
      if (this.props.songbooks.privateSongbooks.previous == null) {
        this.setState({
          activePage: 1
        })
      }
    }
    if (this.props.songbooks.activeSongbook != prevProps.songbooks.activeSongbook) {
      this.setState({
        activeKey: this.props.songbooks.activeSongbook
      })
    }
  }

  onSearchInputChange(e) {
    e.preventDefault();
    const { value, id } = e.target;

    if (id == 'public') {
      this.setState({
        searchText: value
      })
      if (value.length >= 3) {
        this.props.dispatch(fetchSongbooks(null, true, value))
      }
    } else if (id == 'private') {
      this.setState({
        searchTextPriv: value
      })
      if (value.length >= 3) {
        this.props.dispatch(fetchSongbooks(null, false, value))
      }
    }
    
  }

  handlePageChange(pageObj) {
    let pageNumber = pageObj.selected + 1;
    console.log(pageNumber);
    let page = ''
    if (pageNumber > this.state.activePage) {
      page = 'next'
    } else if (pageNumber < this.state.activePage) {
      page = 'previous'
    }
    let songbook_url = ''
    if (this.state.activeKey  == 'private') {
      songbook_url = this.state.privateSongbooks[page]
    } else {
      songbook_url = this.state.publicSongbooks[page]
    }
    let offset = songbook_url !== '' && (pageNumber-1) * 100

    let is_public = this.state.activeKey == 'public' ? true : false

    this.props.dispatch(fetchSongbooks(null, is_public, this.state.activeKey == 'public' ? this.state.searchText : this.state.searchTextPriv, offset));
    this.setState({ activePage: pageNumber });
}

  render() {  
  
    const { songbooks, songbook } = this.props
    let activeKey = this.state.activeKey
    let itemsCount = activeKey == 'private' ? this.state.privateSongbooks.count : this.state.publicSongbooks.count
    return (
      <div className="container-fluid ">
        <div className="row justify-content-center">
          <div className="col-16 col-sm-14 col-md-12 col-lg-8">
            <h1>{gettext('Songbooks')}
              {songbooks.isFetching === true &&
              <div className="text-align-center spinner-container">
                <Spinner inline/>
              </div>
              }
              {this.props.user.isAuthenticated &&
                <div className="svg-plus-container"><SvgPlusLargeRed/></div>
              }
            </h1>


            {this.props.user.isAuthenticated &&
              <SongbookCreate />
            }
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-16 col-sm-14 col-md-12 col-lg-8">
            {this.props.user.isAuthenticated ?
              <>
                <div className="songbooks-search-container">
                  <input type="text" className="private search-input dark-bg" placeholder={gettext('Search')} id={this.state.activeKey} onChange={this.onSearchInputChange} value={this.state.activeKey == 'private' ? this.state.searchTextPriv : this.state.searchText}></input>
                  <SvgSearchRed />
                </div>
                <Tabs defaultActiveKey={this.state.activeKey} id="uncontrolled-tab-example" onSelect={(key) => this.props.dispatch(setActiveSongbook(key))}>
                  <Tab eventKey="private" title={gettext("Private list")}>
                    {this.state.privateSongbooks.results && Object.keys(songbooks.privateSongbooks.results).length > 0 &&
                      songbooks.privateSongbooks.results.map((item, index) => {
                        return <SongbookListItem songbooks={songbooks} isFetching={songbook.isFetching} songbook={item} key={index + "_songbook-private_" + item.id} index={index} user={this.props.user} getSongbook={(id) => this.props.dispatch(fetchSongbookDetail(id, this.state.page))} onlyPublic={false} dispatch={this.props.dispatch} />
                      })}
                  </Tab>
                  <Tab eventKey="public" title={gettext("Public list")}>
                    {this.state.publicSongbooks.results && Object.keys(this.state.publicSongbooks.results).length > 0 &&
                      this.state.publicSongbooks.results.map((item, index) => {
                        return <SongbookListItem songbooks={songbooks} isFetching={songbook.isFetching} songbook={item} key={index + "_songbook-public_" + item.id} index={index} user={this.props.user} getSongbook={(id) => this.props.dispatch(fetchSongbookDetail(id, this.state.page))} onlyPublic={true} dispatch={this.props.dispatch} />
                      })}
                  </Tab>
                </Tabs>
              </> : this.state.publicSongbooks.results && Object.keys(this.state.publicSongbooks.results).length > 0 &&
              <>
                <input type="text" className="public" id="public" placeholder={gettext('Search')} onChange={this.onSearchInputChange} value={this.state.searchText}></input>
                {this.state.publicSongbooks.results.map((item, index) => {
                  return <SongbookListItem songbooks={songbooks} isFetching={songbook.isFetching} songbook={item} key={index + "_songbook_" + item.id} index={index} user={this.props.user} getSongbook={(id) => this.props.dispatch(fetchSongbookDetail(id, this.state.page))} onlyPublic={true} dispatch={this.props.dispatch} />
                })}
              </>
            }
            {!songbooks.isFetching && ((this.state.publicSongbooks.count == 0 && this.state.activeKey == 'public')||(this.state.privateSongbooks.count == 0 && this.state.activeKey == 'private')) &&
              <div>{gettext('No songbooks meet the criteria.')}</div>}
            {itemsCount > 100 && this.props.songbooks.isFetching && <div style={{marginLeft:'50%'}}><Spinner inline /></div>}
            {itemsCount > 100 && <div className="pagination-react">
              {<ReactPaginate
                    className="pagination"
                    disabledClassName="disabled"
                    activeClassName="active"
                    pageCount={Math.ceil(itemsCount/100)}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageChange.bind(this)}
                    previousLabel="⟨"
                    nextLabel="⟩"
                />}
                </div>}
          </div>
        </div>
      </div>
    )

  }
}

SongbookContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    songbooks: {},
    songbook: {},
    user: {},
  }
  f.songbooks = (state.songbooks === undefined) ? {} : state.songbooks
  f.songbook = (state.songbook === undefined) ? {} : state.songbook
  f.user = (state.user === undefined) ? {} : state.user
  return f;
}

export default connect(mapStateToProps)(SongbookContainer);