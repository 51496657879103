import React from "react";
import { connect } from 'react-redux'
import { SONGBOOK_PDF_STEPS } from '../../constants'
import { getStepComponent } from '../../utils'


class StepRenderer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { stepper, index, context } = this.props
        let StepComponent = getStepComponent(stepper[index], context)
        return <StepComponent {...this.props} />
    }
}

StepRenderer.defaultProps = {
    context: 'pdf-generator'
}

export default StepRenderer;
